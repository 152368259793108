<template>
  <section class="caroussel_banner">
    <v-carousel hide-delimiters :show-arrows="false">
      <v-carousel-item v-for="(item,i) in banner.getBanner()" :key="i" :src=" item.src">
        <div style="padding-top: 5%; margin-bottom: 2%; ">
          <!--style="height: 100vh!important;"-->
          <v-row justify="center" no-gutters>
            <v-col :cols="12" sm="12" :md="item.banners.length >1 ?'6' : '12'" v-for="(b,index) in item.banners"
                   :key="index">
                <v-card style="background: transparent; padding: 0px" flat max-width="100%">
                  <v-img :id="'img'+i" :src="b.src" :style="'animation: ' +b.efeito+' 1.5s ease-in-out; cursor: pointer;'"
                         :lazy-src="b.src" @click="IrParaUrl(b.link)" :aspect-ratio="16/11" contain :alt="b.alt">
                  </v-img>
                  <v-card-text style="position: relative;" v-if="b.hasbtn">
                    <v-btn rounded color="orange" class="white--text"  large center top :style="'animation: ' +b.efeito+' 1.5s ease-in-out;'">
                      <h2 style="color: #AC141E" @click="scrollTo('recursos')"><b>Saiba +</b></h2>
                    </v-btn>
                  </v-card-text>
                </v-card>
            </v-col>
          </v-row>
        </div>
      </v-carousel-item>
    </v-carousel>
    <br>
  </section>
</template>

<script async>
  /* eslint-disable */
  import banner from '../../assets/js/img_banner'
  import miscMixin from '../../helpers/misc'

  export default {
    mixins: [miscMixin],
    name: 'SecaoBanner',
    components: {
      TxtBanner: () => import('../../components/TxtBanner.vue')
    },
    data: () => ({
      height: '757px',
      banner,
    }),
    methods: {
      redimensionando(id) {
        var teste_div = document.getElementById(id)
        var eleStyle = window.getComputedStyle(teste_div)
        var height = 760

        var hg = parseInt(eleStyle.width.replace('px', '')) - 430
        if (parseInt(eleStyle.width.replace('px', '')) <= 1100) {
          height = hg > 300 ? hg : 300
        } else {
          height = hg > 300 ? (hg > 760 ? 760 : hg) : 300
        }
        return height
      },
    },
    mounted() {
      var item = document.getElementById('g11533')
      if (item !== null) {
        console.log(item)
        item.addEventListener('click', function() {
          console.log('clicou')
        })
      }



    }
  }
</script>

<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
</style>
