/* eslint-disable */
import parte_1 from '../img/oficial/banner/parte_1.svg'
import parte_2 from '../img/oficial/banner/parte_2.svg'
import fundo3 from '../img/oficial/banner/g28107.png'


const efeitos1Col = ['puffIn','swap','twisterInDown','twisterInUp','vanishIn','swashIn','foolishIn','tinRightIn',
  'tinLeftIn','tinUpIn','tinDownIn','boingInUp','spaceInUp','spaceInDown','openDownRetournLeft','openUpRetournRight',
  'openUpRetournLeft','perspectiveDownRetourn','zoomIn','zoomInDown','slideInDown','slideInUp','rotateIn','flip',
  'flipInX','flipInY','fadeIn','fadeInDown','fadeInUp','bounceIn','perspectiveRetournLeft','perspectiveRetournRight']
// const efeitos1Col = ['openDownLeftRetourn']
const efeitos1ColAlll= ['zoomIn','slideIn','rotateInDown','bounceIn','rotateInUp','spaceIn','openDownRetourn',
'openUpRetourn','openUpRetourn','swap','perspectiveRetourn']

const img_fundo = [
  {
    banner_id: 1,
    src: fundo3,
    banners:[
      {src: parte_1, efeito: '',link: '',hasbtn: false, alt: 'Automatize seu atendimento. Pedidos via whatsapp. Sem taxa.Sem comissão.'},
      {src: parte_2, efeito: '',link: '',hasbtn: true, alt:''},
      // {src: itens_banners, efeito: '',link: '',},
    ],
  },
  // {
  //   banner_id: 1,
  //   src: fundo,
  //   banners:[
  //     {src: img_10, efeito: '',link: '',},
  //   ],
  // }
  // {
  //   banner_id: 1,
  //   src: fundo,
  //   banners:[
  //     {src: img_6, efeito: '',link: '',},
  //   ],
  // },
  // {
  //   banner_id: 1,
  //   src: fundo,
  //   banners:[
  //     {src: img_2, efeito: '',link: '',},
  //     {src: img_1, efeito: '',link: '',},
  //   ],
  // },
  // {
  //   banner_id: 1,
  //   src: fundo,
  //   banners:[
  //     {src: img_5, efeito: '',link: '',},
  //     {src: img_4, efeito: '',link: '',},
  //   ],
  // },
  // {
  //   banner_id: 1,
  //   src: fundo,
  //   banners:[
  //     {src: img_7, efeito: '',link: '',},
  //   ],
  // },


]
export default {
  getBannerFundo() {
    return img_fundo
  },
  getBanner() {

    for(var i=0; i<img_fundo.length; i++){
      var sorteada = Math.floor(Math.random() * efeitos1ColAlll.length);
      var efeito = efeitos1ColAlll[sorteada]
      if (img_fundo[i].banners.length > 1) {
        img_fundo[i].banners[0].efeito = efeito + 'Left'
        img_fundo[i].banners[1].efeito = efeito + 'Right'
      } else {
        var sorteada2 = Math.floor(Math.random() * efeitos1Col.length);
        img_fundo[i].banners[0].efeito = efeitos1Col[sorteada2]
      }
    }
    return img_fundo
  },
  getEfeito(){
    var sorteada = Math.floor(Math.random() * efeitos1ColAlll.length);
    return  efeitos1ColAlll[sorteada]
  }
}
